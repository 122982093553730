import * as React from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Container,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";




const homepagelinks = [
{
  url: "https://uvc.freshservice.com/",
subject: "HelpDesk",
body: "Visit the support portal to log a new Service Request or review outstanding items."
},
{
url: "https://teams.microsoft.com",
subject: "Teams Pod Group ",
body: "Connect with your regional pod team.",
},

{
url: "https://community.unitedvetcare.tech",
subject: "Community ",
body: "Connect with other leaders and professionals across the United Veterinary Care network.",
},

{
url: "https://support.unitedvetcare.com/support/solutions",
subject: "Knowledgebase ",
body: "Searchable repository of articles, processes, how-to guides, and other helpful information.",
},

{
url: "https://unitedvetcare.sharepoint.com/sites/UVCTicketResourceCenter",
subject: "SharePoint Intranet ",
body: "Connect with your Support Center Team, access recorded sessions, and more.",
},

{
url: "https://outlook.office.com",
subject: "Outlook - UVC Email",
body: "Access your UVC email account",
},

{
url: "https://sso.dayforcehcm.com/uvc",
subject: "Dayforce",
body: "Payroll, Time & Attendance, Time off.",
},

{
url: "https://app.powerbi.com/groups/me/apps/ca32cfc7-919a-4973-a51c-48b6be65c38f",
subject: "PowerBI Reporting ",
body: "P&Ls, Revenue, and other in-depth data to keep you informed.",
},

{
url: "https://app.smartsheet.com/folders/ChmwcXvmp68wfRqQP55rCCVwHv4pwXj4mp87gh71",
subject: "Smartsheet Business Plans ",
body: "View and update your Smartsheet business plan",
},

{
path: "/learning",
subject: "UVC Learning",
body: "View your assigned courses, and browse other available courses",
},

{
path: "/coupa",
subject: "UVC Coupa & Stampli",
body: "Invoice Viewing",
},
{
path: "/virtuallearning",
subject: "Virtual Learning Events",
body: "View and register for learning and development webinars",
},

// {
// url: "https://unitedvetcare.sharepoint.com/:b:/g/EV7Qgk5rGQVHrgT4pFono0cBFpPGEz7EDhybpn0W9W0nsQ?e=dJgn8K",
// subject: "Virtual Learning Events",
// body: "View and register for learning and development webinars",
// },
  {
    url: "https://login-usb.mimecast.com/apps#/home",
    subject: "Mimecast Personal Portal",
    body: "Review and release held emails",
    },
    {
      url: "https://optifreight.cardinalhealth.com/default.aspx?Login=Expired&OauthSession=null&RedirectURL=/Protected/Customer/Accounts/MyAccounts.aspx?PageId=1",
      subject: "OptiFreight Shipping Portal",
      body: "Create shipping labels for outbound shipments or view/track shipments",

    },


];


interface Link {
  subject: string;
    body: string;
    url?: string;
    path? :string;
}
interface Props {
  homepagelinks: Link[];
}


const BasicCard = ({ homepagelinks }: Props) => {
  const navigate = useNavigate();


  const HandleLinkClick = (link: Link) => {
    if (link.url) {
      window.open(link.url, "_blank");
    } else if (link.path) {
      navigate(link.path);
    }
  };

  return (
    <Card
      sx={{ height: "160px"}}
      elevation={2}>{homepagelinks.map((link, index) => (
      <CardActionArea key={index} onClick={() => HandleLinkClick(link)}>
        <CardContent>
          <><Typography variant="h5" component="div">
            {link.subject} ➔
           </Typography>
           <Typography variant="body2" paddingTop="1em">
            {link.body}
          </Typography></>
        </CardContent>
        {/* { <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> } */}
      </CardActionArea>))}
    </Card>
  );}


  export default function Homepage() {
    return (
      <Container maxWidth="md" style={{ paddingTop: "2em", paddingBottom: "2em" }}>
        <Grid container spacing={2} maxWidth="lg">
          {homepagelinks.map((link) => (
            <Grid item lg={4} md={4} sm={12} xs={12} >
              <BasicCard homepagelinks={[link]} />
            </Grid>
          ))}
        </Grid>
      </Container>
    );
  }
